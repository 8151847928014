function Termos({visible, autoContraste, tamanhos, tamanho, setVisible }) {

  return visible && ( 
    <div className={`window-term ${autoContraste ? 'contaste' : ''} ${tamanhos[tamanho].fontSize}`}>
      <div className="card" style={{maxWidth: "800px"}}>
        <div className="card-body hold-card-body">
          <div className="hold-content">
            <h5 className="card-title">TERMO DE USO - ESCOLA DO TRABALHADOR 4.0 </h5>
            <h5 className="card-title">POR FAVOR, LEIA ATENTAMENTE ANTES DE REALIZAR O CADASTRO NO WEBSITE DA ESCOLA DO TRABALHADOR 4.0</h5> 
            <br/>
            <p className="card-text left">
              <strong>1. Quais informações estão presentes neste documento?</strong> 
            </p> 
            <p className="card-text left">
            Neste Termo de Uso, o usuário do serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT encontrará informações sobre: o funcionamento do serviço e as regras aplicáveis a ele; o arcabouço legal relacionado à prestação do serviço; as responsabilidades do usuário ao utilizar o serviço; as responsabilidades da administração pública ao prover o serviço; informações para contato, caso exista alguma dúvida ou seja necessário atualizar informações; e o foro responsável por eventuais reclamações caso questões deste Termo de Uso tenham sido violadas.
            </p> 
            <p className="card-text left">
              Além disso, na Política de Privacidade, o usuário do serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT encontrará informações sobre: qual o tratamento dos dados pessoais realizados, de forma automatizada ou não, e a sua finalidade; os dados pessoais dos usuários necessários para a prestação do serviço; a forma como eles são coletados; se há o compartilhamento de dados com terceiros; e quais as medidas de segurança implementadas para proteger os dados.
            </p> 
            <p className="card-text" >
              <strong>2. Aceitação do Termo de Uso e Política de Privacidade</strong> 
            </p> 
            <p className="card-text left">
            Ao utilizar os serviços, o usuário confirma que leu e compreendeu os Termos e Políticas aplicáveis ao serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT e concorda em ficar vinculado a eles. 
            </p> 
            <p className="card-text">
              <strong>3. Definições</strong> 
            </p> 
            <p className="card-text left">
              Para melhor compreensão deste documento, neste Termo de Uso e na Política de Privacidade consideram - se :
            </p> 
            <p className="card-text left">
              Dado pessoal: informação relacionada a pessoa natural identificada ou identificável; 
            </p> 
            <p className="card-text left">
             Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento
            </p> 
            <p className="card-text left">
             Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
            </p> 
            <p className = "card-text left">
             Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento e dados pessoais em nome do controlador.
            </p> 
            <p className="card-text left">
             Encarregado: pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
            </p> 
            <p className="card-text left">
             Agentes de tratamento: o controlador e o operador.
            </p> 
            <p className="card-text left">
              Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
            </p> 
            <p className="card-text left">
              Uso compartilhado de dados: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entres privados.
            </p> 
            <p className="card-text left">
              Autoridade nacional: órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei em todo o território nacional.           
           </p> 
            <p className="card-text left">
            Agente público: Todo aquele que exerce, ainda que transitoriamente ou sem remuneração, por eleição, nomeação, designação, contratação ou qualquer outra forma de investidura ou vínculo, mandato, cargo, emprego ou função nos órgãos e entidades da Administração Pública, direta e indireta.
            </p> 
            <p className="card-text left">
              Terceiro: Pessoa ou entidade que não participa diretamente em um contrato, em um ato jurídico ou em um negócio, ou que, para além das partes envolvidas, pode ter interesse num processo jurídico.            </p> 
            <p className="card-text left">
             Usuários (ou "Usuário", quando individualmente considerado): todas as pessoas naturais que utilizarem o serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT.
            </p> 
            <p className="card-text left">
            Uso compartilhado de dados: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.
           </p> 
            <p className="card-text left">
            Violação de dados pessoais: é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
            </p> 
            <p className="card-text left">
            Outras bases de dados governamentais: registros referentes as informações socioeconômicas, parcelas do seguro-desemprego, vínculos empregatícios, informações previdenciárias constantes da RAIS, CAGED, e-Social
            </p> 
            <p className="card-text left">
              Códigos maliciosos: qualquer programa de computador, ou parte de um programa, construído com a intenção de provocar danos, obter informações não autorizadas ou interromper o funcionamento de sistemas ou redes de computadores; 
            </p> 
            <p className="card-text left">
              Internet: sistema constituído de conjunto de protocolos lógicos, estruturado em escala mundial para uso público e irrestrito, com a finalidade de possibilitar a comunicação de dados entre terminais por meio de diferentes redes; 
            </p> 
            <p className="card-text left">
              Usuário: qualquer pessoa natural que utiliza o serviço Escola do Trabalhador 4.0. 
            </p> 
            <p className="card-text left">
              Outras bases de dados governamentais: registros referentes as informações socioeconômicas, parcelas do seguro desemprego, vínculos empregatícios, informações previdenciárias constantes da RAIS, CAGED, eSocial, CNIS e bases do Seguro Desemprego; 
            </p> 
            <p className="card-text">
              <strong>4. O que é a Escola do Trabalhador 4.0 – Microsoft</strong> 
            </p> 
            <p className="card-text left">
             Com a finalidade de contribuir para o aumento da empregabilidade do trabalhador brasileiro, constante da política pública de qualificação profissional, o Ministério do Trabalho e Previdência implementou a Escola do Trabalhador 4.0. O propósito da iniciativa é qualificar o cidadão em habilidades digitais para o mercado de trabalho. Para isso, foi disponibilizada, pela Microsoft, uma plataforma de ensino a distância contendo cursos de curta formação. Os interessados em participar da oferta de qualificação da Escola do Trabalhador 4.0, deverão preencher formulário de cadastro. Nesse formulário, são indicadas informações da pessoa que irá usufruir dos cursos. Os dados são alimentados em banco de dados nacional, a partir do qual são gerados relatórios que possibilitam a melhoria da política pública
           </p> 
            <p className="card-text left">
             Por meio do cadastro será possível possuir uma base de dados consolidados, centralizados e atualizados, além de informações qualificadas para o estabelecimento de ações de intermediação de mão de obra a fim de reduzir o número de trabalhadores desempregados e requalificar aqueles que buscam novas oportunidades. Assim, por meio dos dados e informações captadas no cadastro, busca-se construir uma base de auxílio para a melhoria da política de qualificação profissional e de intermediação de mão de obra.
           </p> 
           <p className="card-text left">
           Os programas oferecidos na plataforma Escola do Trabalhador 4.0 serão destinados ao público com idade igual ou superior a 14 anos.           </p> 
           <p className="card-text">
              <strong> 5. Quais são as leis e normativos aplicáveis a esse serviço? </strong> 
            </p> 
            <p className="card-text">
              - Lei nº 12.965, de 23 de abril de 2014 - Marco Civil da Internet – Estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil. 
            </p> 
            <p className = "card-text left">
              - Lei nº 12.527, de 18 de novembro de 2011 - Lei de Acesso à Informação – Regula o acesso a informações previsto na Constituição Federal.
            </p> 
            <p className="card-text">
              <strong> 6. Quais são os direitos do usuário do serviço? </strong> 
            </p>  
            <p className="card-text left">
             O usuário do serviço possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:
            </p> 
            <p className="card-text left">
              - Direito de confirmação e acesso (Art. 18, I e II): é o direito do usuário de obter do serviço a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais.
            </p> 
            <p className="card-text left">
              - Direito de retificação (Art. 18, III): é o direito de solicitar a correção de dados incompletos, inexatos ou desatualizados. 
           </p> 
            <p className="card-text left">
             - Direito à limitação do tratamento dos dados (Art. 18, IV): é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo exigir a eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei Geral de Proteção de Dados.
            </p>
            <p className="card-text">
             - Direito de oposição (Art. 18, § 2º): é o direito do usuário de, a qualquer momento, se opor ao tratamento de dados por motivos relacionados com a sua situação particular, com fundamento em uma das hipóteses de dispensa de consentimento ou em caso de descumprimento ao disposto na Lei Geral de Proteção de Dados.
            </p>
            <p className="card-text left">
             - Direito de portabilidade dos dados (Art. 18, V): é o direito do usuário de realizar a portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial.
            </p>
            <p className="card-text left">
             - Direito de não ser submetido a decisões automatizadas (Art. 20, LGPD): o titular dos dados tem direito a solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
           </p>
            <p className="card-text left">
            <strong>7. Quais são as obrigações dos usuários que utilizam o serviço?</strong> 
            </p>
            <p className="card-text">
             O usuário se responsabiliza pela precisão e veracidade dos dados informados e reconhece que a inconsistência destes poderá implicar a impossibilidade de se utilizar o serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT.
            </p>
            <p className="card-text left">
             Durante a utilização do serviço, a fim de resguardar e de proteger os direitos de terceiros, o usuário se compromete a fornecer somente seus dados pessoais, e não os de terceiros.
            </p>
            <p className="card-text left">
             O login e senha só poderão ser utilizados pelo usuário cadastrado. Ele se compromete em manter o sigilo da senha, que é pessoal e intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido, após o ato de compartilhamento.
            </p>
            <p className="card-text left">
             O usuário do serviço é responsável pela atualização das suas informações pessoais e consequências na omissão ou erros nas informações pessoais cadastradas.
            </p>
            <p className="card-text">
             O Usuário é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados à Administração Pública, a qualquer outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso ao serviço.
            </p>
            <p className="card-text left">
            O Órgão não poderá ser responsabilizado pelos seguintes fatos:
            </p>
            <p className="card-text left">
            a. Equipamento infectado ou invadido por atacantes;
            </p>
            <p className="card-text left">
            b. Equipamento avariado no momento do consumo de serviços;
            </p>
            <p className="card-text left">
            c. Proteção do computador;
            </p>
            <p className="card-text left">
            d. Proteção das informações baseadas nos computadores dos usuários;
            </p>
            <p className="card-text left">
            e. Abuso de uso dos computadores dos usuários;
            </p>
            <p className="card-text left">
            f. Monitoração clandestina do computador dos usuários;
            </p>
            <p className="card-text">
            g. Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;
            </p>
            <p className="card-text left">
            h. Perímetro inseguro;
            </p>
            <p className="card-text left">
            Em nenhuma hipótese, a Administração Pública Federal será responsável pela instalação no equipamento do Usuário ou de terceiros, de códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a ser criados), em decorrência da navegação na Internet pelo Usuário.
            </p>
            <p className="card-text left">
              <strong> 8. Quais são as responsabilidades da administração pública com meus dados?</strong> 
            </p>
            <p className="card-text left">
              A Administração Pública se compromete a cumprir todas as legislações inerentes ao uso correto dos dados pessoais do cidadão de forma a preservar a privacidade dos dados utilizados no serviço, bem como a garantir todos os direitos e garantias legais dos titulares dos dados. Ela também se obriga a promover, independentemente de requerimentos, a divulgação em local de fácil acesso, no âmbito de suas competências, de informações de interesse coletivo ou geral por eles produzidas ou custodiadas. É de responsabilidade da Administração Pública implementar controles de segurança para proteção dos dados pessoais dos titulares.
           </p>
            <p className="card-text left">
               A Administração Pública poderá, quanto às ordens judiciais de pedido das informações, compartilhar informações necessárias para investigações ou tomar medidas relacionadas a atividades ilegais, suspeitas de fraude ou ameaças potenciais contra pessoas, bens ou sistemas que sustentam o Serviço ou de outra forma necessária para cumprir com nossas obrigações legais. Caso ocorra, a Administração Pública notificará os titulares dos dados, salvo quando o processo estiver em segredo de justiça.
           </p>
            <p className="card-text left">
              A Administração Pública não poderá ser responsabilizada pelos seguintes fatos:
            </p>
            <p className="card-text left">
              <strong> 9. Este Termo de Uso pode ser alterado?</strong> 
            </p>
            <p className="card-text left">
              A presente versão desta Política de Privacidade foi atualizada pela última vez em: 18/02/2022
            </p>
            <p className="card-text">
             O editor se reserva o direito de modificar, a qualquer momento o site as presentes normas, especialmente para adaptá-las às evoluções do serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
            </p>
            <p className="card-text left">
             Qualquer alteração e/ou atualização destes Termos de Uso e Política de Privacidade passará a vigorar a partir da data de sua publicação no sítio do serviço e deverá ser integralmente observada pelos Usuários.
            </p>
            <p className="card-text left">
              <strong>10. Qual o contato pelo qual o usuário do serviço pode tirar suas dúvidas?</strong> 
            </p>
            <p className="card-text">
             O Sistema de Informação ao Cidadão e a Ouvidoria são disponibilizados para que os titulares dos dados pessoais possam demandar as solicitações previstas pelo art. 18º da LGPD e para sanar quaisquer dúvidas sobre este o serviço ou sobre este Termo de Uso e Política de Privacidade
            </p>
            <br/>
            <p className="card-text left">
            <strong>POLÍTICA DE PRIVACIDADE</strong> 
            </p>
            <p className="card-text left">
              Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet) e com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais).
           </p>
            <p className="card-text left">
              Ao utilizar a ferramenta Escola do Trabalhador 4.0 - MICROSOFT, o titular dos dados está ciente que as suas informações relacionadas a endereço, sexo, gênero, raça, contatos, escolaridade, vínculos empregatícios, qualificações profissionais e perfil de utilização das ferramentas digitais da iniciativa poderão ser utilizados para fins de avaliação e monitoramento de políticas públicas, assim como para a personalização da prestação dos serviços.
            </p>
            <p className="card-text left">
              A presente Política de Privacidade define a forma como os dados pessoais coletados serão armazenados, tratados e acessados. O que se segue deve ser lido cuidadosamente para que sejam compreendidos os pontos de vista e práticas dos parceiros, relativos aos dados pessoais dos usuários e a forma como os trataremos.
            </p>
            <p className="card-text left">
             Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
            </p>
            <p className="card-text left">
            O site se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados (LGPD), e respeitar os princípios dispostos no Art. 6º:
            </p>
            <p className="card-text">
             I - finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;
           </p>
            <p className="card-text left">
             II - adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do tratamento;
            </p>
            <p className="card-text left">
              III - necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de dados;
            </p>
            <p className="card-text left">
              IV - livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;  
            </p>
            <p className="card-text left">
              V - qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
           </p>
            <p className="card-text left">
              VI - transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e industrial;
           </p>
            <p className="card-text left">
              VII - segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;
            </p>
            <p className="card-text left">
              VIII - prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;
            </p>
            <p className="card-text left">
            IX - não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;
            </p>
            <p className="card-text left">
            IX - não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;
            </p>
            <p className="card-text left">
            X - responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas.
           </p>
            <p className="card-text">
              <strong>11. A quem compete as decisões referentes ao tratamento de dados pessoais realizado no serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT (Controlador)?</strong>
            </p>
            <p className="card-text left">
               A Lei Geral de Proteção de Dados define como controlador, em seu artigo 5º:
            </p>
            <p className="card-text left">
              Art. 5º, VI – controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;
            </p>
            <p className="card-text left">
              Para o serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT, as decisões referentes ao tratamento de dados pessoais são de responsabilidade do Ministério do Trabalho e Previdência.
           </p>
           <p className="card-text left">
             Endereço: Esplanada dos Ministérios – Bloco F, Brasília, DF, CEP: 70059-900.
           </p>
           <p className="card-text left">
            E-mail: sucap@economia.gov.br.
           </p>
           <p className="card-text left">
            Telefone: (61) 2031-6516.
           </p>
            <p className="card-text">
              <strong>12. Quem realiza o tratamento de dados (Operador)?</strong> 
            </p>
            <p className="card-text left">
             A Lei Geral de Proteção de Dados define como operador, em seu artigo 5º:
            </p>
            <p className="card-text left">
              Art. 5º, VII - operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
            </p>
            <p className="card-text left">
             Para o serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT, quem realiza o tratamento de dados pessoais em nome do controlador é o operador Associação para Promoção da Excelência do Software Brasileiro - SOFTEX. Endereço: Ed. Libertas, bloco M, Asa Sul, Brasília, Distrito Federal, 70070-010.
            </p>
            <p className="card-text left">
            <strong>13. Quem é o responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (Encarregado)?</strong>
            </p>
            <p className="card-text left">
             A Lei Geral de Proteção de Dados define como encarregado, em seu artigo 5º:
            </p> <p className="card-text left">
            Art. 5º, VIII – pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
            </p>
             <p className="card-text left">
             Para o serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT, que é responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados é o encarregado Marta Juvina de Medeiros.
            </p>
            <p className="card-text left">
            E-mail: encarregado@economia.gov.br.
            </p>
             <p className="card-text left">
             <strong>14. Quais dados pessoais são tratados pelo serviço?</strong>      
            </p> 
            <p className="card-text left">
            -Nome completo
            </p> 
            <p className="card-text left">
            -Data de nascimento
            </p>
            <p className="card-text left">
            -Sexo
            </p>
            <p className="card-text left">
            -Nacionalidade
            </p>
            <p className="card-text left">
            -Número de inscrição no CPF
            </p>
            <p className="card-text left">
            -Estado civil
            </p>
            <p className="card-text left">
            -Endereço de e-mail
            </p>
            <p className="card-text left">
            -Endereço
            </p>
            <p className="card-text left">
            -Número de telefone
            </p>
            <p className="card-text left">
            -Localização do usuário
            </p>
            <p className="card-text left">
            -Registro de acesso
            </p>
            <p className="card-text left">
            -Endereço
            </p>
            <p className="card-text left">
            -Situação de empregabilidade
            </p>
            <p className="card-text left">
            -Escolaridade
            </p>
            
            <p className="card-text left">
            <strong>15. Como os dados são coletados?</strong>
            </p>
            <p className="card-text left">
             Nome completo: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Nome social: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Data de nascimento: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Sexo: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Nacionalidade: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Número de inscrição no CPF: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Estado civil: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
             Endereço de e-mail: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
            Número de telefone: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
            Localização do usuário: Localização do dispositivo no cadastro
            </p>
            <p className="card-text left">
            Registro de acesso: Obtida pelo dispositivo de acesso, após autorização do usuário
            </p>
            <p className="card-text left">
            Raça: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
            Situação de empregabilidade: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
            Escolaridade: Informado pelo usuário no cadastro
            </p>
            <p className="card-text left">
            <strong>16. Para que fim utilizamos seus dados?</strong>
            </p>
            <p className="card-text left">
            Nome completo: Identificação do usuário dentro do serviço e criação de login
            </p>
            <p className="card-text left">
            Nome social: Identificação do usuário dentro do serviço e criação de login
            </p>
            <p className="card-text left">
            Data de nascimento: O dado é necessário para identificação do usuário dentro do serviço
            </p>
            <p className="card-text left">
            Sexo: O dado é necessário para identificação do usuário dentro do serviço
            </p>
            <p className="card-text left">
            Nacionalidade: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Número de inscrição no CPF: O dado é necessário para identificação do usuário dentro do
            </p>
            <p className="card-text left">
            Estado civil: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Endereço de e-mail: O dado é necessário para identificação do usuário dentro do serviço
            </p>
            <p className="card-text left">
            Endereço: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Número de telefone: O dado é necessário para identificação do usuário dentro do serviço
            </p>
            <p className="card-text left">
            Localização do usuário: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Registro de acesso: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Raça: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Situação de empregabilidade: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            Escolaridade: O dado é necessário para melhorar e personalizar a experiência do usuário
            </p>
            <p className="card-text left">
            <strong>17. Qual o tratamento realizado com os dados pessoais?</strong>
            </p>
            <p className="card-text left">
            Nome completo: ACESSO, ARMAZENAMENTO, COLETA
            </p>
            <p className="card-text left">
            Nome social: ACESSO, ARMAZENAMENTO, COLETA
            </p>
            <p className="card-text left">
            Data de nascimento: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO
            </p>
            <p className="card-text left">
            Sexo: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO
            </p>
            <p className="card-text left">
            Nacionalidade: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO
            </p>
            <p className="card-text left">
            Número de inscrição no CPF: ACESSO, ARMAZENAMENTO, COLETA, PROCESSAMENTO, PRODUÇÃO 
            </p>
            <p className="card-text left">
            Estado civil: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO
            </p>
            <p className="card-text left">
            Endereço de e-mail: ARMAZENAMENTO, COLETA
            </p>
            <p className="card-text left">
            Endereço: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO, PRODUÇÃO
            </p>
            <p className="card-text left">
            Número de telefone: ACESSO, ARMAZENAMENTO, COLETA
            </p>
            <p className="card-text left">
            Localização do usuário: COLETA
            </p>
            <p className="card-text left">
            Registro de acesso: COLETA
            </p>
            <p className="card-text left">
            Raça: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO
            </p>
            <p className="card-text left">
             Situação de empregabilidade: ARMAZENAMENTO, AVALIAÇÃO, COLETA, DISTRIBUIÇÃO, PROCESSAMENTO, PRODUÇÃO
            </p>
            <p className="card-text left">
            Escolaridade: ARMAZENAMENTO, AVALIAÇÃO, COLETA, PROCESSAMENTO, PRODUÇÃO
            </p>

            <p className="card-text left">
            <strong>18. Os dados pessoais utilizados no serviço são compartilhados?</strong>
            </p>
            <p className="card-text left">
            Os dados coletados poderão ser compartilhados com as Secretarias componentes do Ministério do Trabalho e Previdência com o objetivo de acompanhamento da política e intermediação de mão de obra. Além disso, poderão ser compartilhados com parceiros, desde que estes assinem previamente um Termo de Responsabilidade estabelecido pelo Operador de Dados.
            </p>

            <p className="card-text left">
              <strong>19. Segurança no tratamento dos dados pessoais do usuário</strong>
            </p>
            <p className="card-text left">
              O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
           </p>
            <p className="card-text left">
             Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.
            </p>
            <p className="card-text left">
             O site utiliza criptografia para que os dados sejam transmitidos de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
            </p>
            <p className="card-text left">
            No entanto, o site se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT se compromete, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.
            </p>
            <p className="card-text left">
            A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
           </p>
            <p className="card-text left">
            Por fim, o site se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.
            </p>
            <p className="card-text left">
            <strong> 20. O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT utiliza cookies?</strong>
            </p>
            <p className="card-text left">
            Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do site.
            </p>
            <p className="card-text left">
            Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site.
            </p>
            <p className="card-text left">
            É importante ressaltar que nem todo cookie contém dados pessoais do usuário, já que determinados tipos de cookies podem ser utilizados somente para que o serviço funcione corretamente.
            </p>
            <p className="card-text left">
            As informações eventualmente armazenadas em cookies também são consideradas dados pessoais e todas as regras previstas nesta Política de Privacidade também são aplicáveis a eles.
           </p>
            <p className="card-text left">
            O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT utiliza cookies para Armazenamento de informações de login e progresso nos cursos.
           </p>
            <p className="card-text left">
            <strong> 21. O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT faz uso de cookies de terceiros?</strong>
            </p>
            <p className="card-text left">
            O serviço ESCOLA DO TRABALHADOR 4.0 - MICROSOFT utiliza cookies de terceiros. Podemos utilizar os cookies do serviço https://login.microsoftonline.com/ para Criação de cadastro na conta Microsoft.
            </p>
            <p className="card-text left">
            <strong> 22. Qual o foro aplicável caso o usuário queira realizar alguma reclamação?</strong>
            </p>
            <p className="card-text left">
            Este Termo será regido pela legislação brasileira. Qualquer reclamação ou controvérsia com base neste Termo será dirimida exclusivamente pela comarca de Brasília-DF.
            </p>
            <p className="card-text left">
            Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados têm direito a apresentar reclamação à Autoridade Nacional de Proteção de Dados.
            </p>


          </div> 
          <section className="footer-terms" onClick={() => setVisible(false)}>
            <a className={`btn ${autoContraste ? 'btn-outline-light' : 'btn-yellow'} ${tamanhos[tamanho].btnSize}`} onClick={() => setVisible(false)}>
              Fechar
            </a>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Termos;