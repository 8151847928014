import axios from 'axios';

const instanceAxios = axios.create({
  //baseURL: 'https://be-escolatrabalhador40-prd.azurewebsites.net/api/',
  baseURL: 'https://be-escolatrabalhador40-prod.azurewebsites.net/api/',
  auth: {
    username: 'bigbrain',
    password: 'Abc@1234'
  },
  headers: { 
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});

export default instanceAxios;