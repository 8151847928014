function Fim({ autoContraste, tamanhos, tamanho, data }) {
  return (
    <div className="fim">
      <div className="hold-form">
        <h2>Seu cadastrado foi concluído com sucesso!</h2>
        <p>Suas credencias de acesso foram enviadas para o e-mail {data.emailPessoal}.</p>
        <p>Se não chegar dentro de alguns minutos, verifique na caixa de spam.</p>
      </div>
      <br/>
      <button 
        type="button" 
        className={`btn ${autoContraste ? 'btn-outline-light' : 'btn-blue'} ${tamanhos[tamanho].btnSize}`} 
        onClick={() => window.open('https://servicos.escoladotrabalhador40.com.br', '_blank')}>
        Fazer login
      </button>
    </div>
  );
}

export default Fim;