import { useState, useEffect, useCallback, useMemo } from "react";
import { cpfMask } from "../masks/cpfMask";
import instAxios from "../instanceAxios";
import xImage from "../images/x.svg";
import { info } from "sass";

let timerEmail; // para validar o email do usuário
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const ref = urlParams.get('ref');
let is_depen = ref === 'd37156ac48810a85399020899487ea2e'
let cpf_generated = '';
if(is_depen) {
  cpf_generated = gerarCpf()
}

function gerarCpf() {
  const num1 = aleatorio(); //aleatorio já devolve string, logo não precisa de toString
  const num2 = aleatorio();
  const num3 = aleatorio();

  const dig1 = dig(num1, num2, num3); //agora só uma função dig
  const dig2 = dig(num1, num2, num3, dig1); //mesma função dig aqui

  //aqui com interpolação de strings fica bem mais legivel
  return `${num1}.${num2}.${num3}-${dig1}${dig2}`;
}

//o quarto parametro(n4) só será recebido para o segundo digito
function dig(n1, n2, n3, n4) { 
  
  //as concatenações todas juntas uma vez que são curtas e legíveis
  const nums = n1.split("").concat(n2.split(""), n3.split(""));
  
  if (n4 !== undefined){ //se for o segundo digito coloca o n4 no sitio certo
    nums[9] = n4;
  }
  
  let x = 0;
   
  //o j é também iniciado e incrementado no for para aproveitar a própria sintaxe dele
  //o i tem inicios diferentes consoante é 1º ou 2º digito verificador
  for (let i = (n4 !== undefined ? 11:10), j = 0; i >= 2; i--, j++) {
    x += parseInt(nums[j]) * i;
  }
  
  const y = x % 11;
  //ternário aqui pois ambos os retornos são simples e continua legivel
  return y < 2 ? 0 : 11 - y; 
}

function aleatorio() {
  const aleat = Math.floor(Math.random() * 999);
 //o preenchimento dos zeros à esquerda é mais facil com a função padStart da string
  return ("" + aleat).padStart(3, '0'); 
}


function AlterarSenha({
  autoContraste,
  tamanhos,
  tamanho,
  setPage,
  setData,
  setShowProgress,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [emailFree, setEmailFree] = useState(null);
  const [emailFreeMsg, setEmailFreeMsg] = useState("");
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailToUse, setEmailToUse] = useState("");
  const [usuarioId, setUsuarioId] = useState(null);
  const [sending, setSending] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [form, setForm] = useState({
    email: "",
    senha: "",
    Cpf: "",
    emailPessoal: "",
    confirmarSenha: "",
  });

  //asdsad


  const rulesForm = useMemo(
    () => ({
      email: ["required", ["minLength", 3], "usedLogin"],
      senha: ["required", ["minLength", 8]],
      Cpf: is_depen ? [] : ["required", "validCpf"],
      emailPessoal: ["required"],
      confirmarSenha: ["required", "equalSenha"],
    }),
    []
  );

  const errorMessages = useMemo(
    () => ({
      email: {
        required: "Por favor digite um nome de usuário.",
        minLength: `O nome de usuário deve ter no mínimo ${rulesForm.email[1][1]} letras.`,
        usedLogin: "Esse login já esta em uso, por favor digite outro.",
      },
      senha: {
        required: "Por favor digite uma senha.",
        minLength: `A senha deve ter no mínimo ${rulesForm.senha[1][1]} caracteres.`,
      },
      emailPessoal: {
        required: "Por favor digite seu email pessoal.",
      },
      Cpf: {
        required: "Por favor digite seu CPF.",
        validCpf: "Por favor digite um CPF válido.",
      },
      confirmarSenha: {
        required: "Por favor redigite sua senha.",
        equalSenha: "As senhas devem ser iguais.",
      },
    }),
    [rulesForm.email, rulesForm.senha]
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (name === "Cpf") {
      value = cpfMask(value);
    }

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const handleVoltar = (event) => {
    if (event) event.preventDefault();
    setPage("inicio");
  };

  const validateMinLength = useCallback(
    (field, length) => {
      if (field && length && form) {
        if (form[field] && form[field].length < length) {
          return false;
        }
      }
      return true;
    },
    [form]
  );

  const validateField = useCallback(
    (field) => {
      if (submitted && rulesForm && rulesForm[field]) {
        for (const rule of rulesForm[field]) {
          if (rule === "required") {
            if (!form[field]) {
              return { valid: false, msg: errorMessages[field].required };
            }
          } else if (typeof rule === "object") {
            if (rule[0] === "minLength" && !validateMinLength(field, rule[1])) {
              return { valid: false, msg: errorMessages[field].minLength };
            }
          } else if (rule === "usedLogin") {
            if (emailFree === false) {
              return { valid: false, msg: emailFreeMsg };
            }
          } else if (rule === "validCpf") {
            if (!validaCpf(form[field])) {
              return { valid: false, msg: errorMessages[field].validCpf };
            }
          } else if (rule === "equalSenha") {
            if (form.senha !== form.confirmarSenha) {
              return { valid: false, msg: errorMessages[field].equalSenha };
            }
          }
        }
      }
      return { valid: true, msg: "" };
    },
    [
      submitted,
      rulesForm,
      errorMessages,
      validateMinLength,
      emailFree,
      emailFreeMsg,
      form,
    ]
  );

  const isValid = useCallback(
    (field) => {
      return validateField(field).valid;
    },
    [validateField]
  );

  const getErrorMessage = (field) => {
    return validateField(field).msg;
  };

  const validaCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
    let result = true;
    [9, 10].forEach(function (j) {
      let soma = 0;
      let r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach(function (e, i) {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      r = soma % 11;
      r = r < 2 ? 0 : 11 - r;
      if (r.toString() !== cpf.substring(j, j + 1)) {
        result = false;
      }
    });
    return result;
  };

  const validateForm = useCallback(() => {
    const validFields = [];
    for (const field of Object.keys(form)) {
      validFields.push(isValid(field));
    }
    return !validFields.some((v) => v === false);
  }, [form, isValid]);

  useEffect(() => {
    const validaLogin = () => {
      // usa um debounce pra não consultar trocentas vezes
      if (form.email && form.email.length >= rulesForm.email[1][1]) {
        setCheckingEmail(true);
        if (timerEmail) {
          clearTimeout(timerEmail);
          timerEmail = null;
        }
        timerEmail = setTimeout(() => {
          setShowProgress(true);
          instAxios
            .get(`UsuarioCadastro/email/validate?email=${form.email}`)
            .then((response) => {
              // console.log('resposta positiva',response);
              setEmailFree(response.data.success);
              setEmailFreeMsg(response.data.message);
              setEmailToUse(response.data.email);
              
            })
            .catch((error) => {
              console.log("deu erro", error);
              setEmailFree(false);
              setEmailFreeMsg(
                "Este nome de usuário não está disponível, por favor tente outro nome."
              );
            })
            .finally(() => {
              setCheckingEmail(false);
              setShowProgress(false);
            });
        }, 2000);
      }
    };

    validaLogin();
  }, [form.email, rulesForm.email, setShowProgress]);

  useEffect(() => {
    if (usuarioId) {
      setData((data) => ({ ...data, usuarioId }));
      setTimeout(() => {
        setPage("cadastro");
      }, 300);
    }
  }, [usuarioId, setData, setPage]);

  useEffect(() => {
    if (submitted) handleSubmit(null);
  }, [submitted]);

  const handleSubmit = useCallback(
    (event) => {
      
      if(is_depen) {
        setForm((form) => ({
          ...form,
          Cpf: cpf_generated,
        }));
      }

      if (event) event.preventDefault();
      if (!submitted) {
        setSubmitted(true);
      } else {
        if (!checkingEmail && validateForm() && !sending) {
          setSending(true);
          const { email, senha, Cpf, emailPessoal } = form;
          setShowProgress(true);
          instAxios
            .post("UsuarioCadastro/user/", { email, senha, Cpf, emailPessoal })
            .then((response) => {
              // console.log('resposta positiva',response);
              if (response.data.success === true) {
                setData((data) => ({
                  ...data,
                  senha,
                  Cpf,
                  emailPessoal,
                  email: emailToUse,
                }));
                setUsuarioId(response.data.usuarioId);
              } else {
                setShowAlert(response.data.message);
              }
            })
            .catch((error) => {
              console.log("deu erro", error);
            })
            .finally(() => {
              setShowProgress(false);
              setSending(false);
            });
        }
      }
    },
    [
      checkingEmail,
      emailToUse,
      form,
      sending,
      setData,
      setShowProgress,
      submitted,
      validateForm,
    ]
  );

  return (
    <div className="hold-form">
      <h2>Cadastro</h2>
      <form>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <label>Usuário*</label>
              <input
                required
                type="email"
                className={`form-control ${
                  !isValid("email") || emailFree === false ? "is-invalid" : ""
                }${emailFree === true ? "is-valid" : ""}`}
                name="email"
                title="Nome de Usuário"
                value={form.email}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {emailFreeMsg || getErrorMessage("email")}
              </div>
              <div className="valid-feedback">
                {/* Este usuário esta livre para ser usado. */}
                {emailFreeMsg}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6" style={is_depen ? {display: 'none'} : {display: 'block'}}>
            <div className="mb-3">
              <label>CPF*</label>
              <input
                type="tel"
                className={`form-control ${
                  !isValid("Cpf") ? "is-invalid" : ""
                }`}
                name="Cpf"
                value={is_depen ? cpf_generated : form.Cpf}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">{getErrorMessage("Cpf")}</div>
            </div>
          </div>
          <div className={is_depen ? "col-12" : "col-6"}>
            <div className="mb-3">
              <label>E-mail*</label>
              <input
                type="email"
                className={`form-control ${
                  !isValid("emailPessoal") ? "is-invalid" : ""
                }`}
                name="emailPessoal"
                value={form.emailPessoal}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("emailPessoal")}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <label>Senha*</label>
              <input
                type="password"
                className={`form-control ${
                  !isValid("senha") ? "is-invalid" : ""
                }`}
                name="senha"
                value={form.senha}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">{getErrorMessage("senha")}</div>
              <div className="descricao">
                A senha deve conter ao menos um número, uma letra maiúscula e um
                caracter especial. <br />
                Exemplo:@, *, &, !, etc.
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label>Confirme sua senha*</label>
              <input
                type="password"
                className={`form-control ${
                  !isValid("confirmarSenha") ? "is-invalid" : ""
                }`}
                name="confirmarSenha"
                value={form.confirmarSenha}
                onChange={handleInputChange}
                disabled={sending}
              />
              <div className="invalid-feedback">
                {getErrorMessage("confirmarSenha")}
              </div>
            </div>
          </div>
        </div>

        <div className="text-right">
          <button
            type="submit"
            className={`btn ${
              autoContraste ? "btn-outline-light" : "btn-blue"
            } ${tamanhos[tamanho].btnSize}`}
            onClick={handleSubmit}
            disabled={
              submitted
                ? checkingEmail || !validateForm() || sending
                : checkingEmail
            }
          >
            Cadastrar
          </button>
        </div>
        <br />
        {showAlert && (
          <>
            <br />
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <strong>Atenção!</strong> {showAlert}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert("")}
              >
                <img alt="x" src={xImage} />
              </button>
            </div>
          </>
        )}
        <br />
        <a href="#" onClick={handleVoltar}>
          Voltar
        </a>
      </form>
    </div>
  );
}

export default AlterarSenha;
