import './progressbar.scss';

function Progressbar({hide}) {
  return (
    <div className={`slider ${hide?'hide':''}`}>
      <div className="line"></div>
      <div className="subline inc"></div>
      <div className="subline dec"></div>
    </div>
  )
} 

export default Progressbar;