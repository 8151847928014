import { useEffect, useState } from "react";
import "./App.scss";
import Logo from "./images/logo.png";
// import LogoBlack from './images/logo-black.png';
import Logos1 from "./images/logo-softex-branca.png";
import Logos2 from "./images/logo-emprega.png";
import Logos3 from "./images/logo-microsoft.png";
import Logos4 from "./images/logo-me.png";
import Logos5 from "./images/logo-cepec.png";
import Logos6 from "./images/logo-caminho-digital.png";
import Logos7 from "./images/logo-ministerio-trabalho-branca.png";
import Progressbar from "./components/progressbar";
import IconeService from "./images/icone_botaoservice.png";
import IconeTermos from "./images/iconsTermos1.png";
import IconeTutorial from "./images/iconetutorial1.png";
//import ReorderIcon from "@material-ui/icons/Reorder";

import Inicio from "./pages/inicio";
import AlterarSenha from "./pages/alterar-senha";
import Cadastro from "./pages/cadastro";
import Fim from "./pages/fim";
import Termos from "./pages/termos";
import { MdDescription } from 'react-icons/md';
import { MdPlayCircle } from 'react-icons/md';
import { MdLayers, MdReorder } from 'react-icons/md';

function App() {
  const [tamanho, setTamanho] = useState(0);
  const [autoContraste, setAutoContraste] = useState(false);
  const [page, setPage] = useState("inicio");
  const [data, setData] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [showTermos, setShowTermos] = useState(false);
  const [showList, setShowList] = useState(false);

  const tamanhos = {
    0: {
      fontSize: "",
      btnSize: "",
    },
    1: {
      fontSize: "x2",
      btnSize: "x2",
    },
    2: {
      fontSize: "x3",
      btnSize: "x3",
    },
  };

  const aumentarFonte = () => {
    if (tamanho < 2) {
      setTamanho(tamanho + 1);
    } else {
      setTamanho(2);
    }
  };

  const diminuirFonte = () => {
    if (tamanho > 0) {
      setTamanho(tamanho - 1);
    } else {
      setTamanho(0);
    }
  };
  const openNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  }


  return (
    <section className="geral home">
      <Progressbar hide={!showProgress} />
      <nav className="navbar navbar-expand-lg navbar-light bg-green">
        <div className="hold-navbar">
          <div
            className={tamanhos[tamanho].btnGroup}
            role="group"
            aria-label="tamanhos-cor"
          >
            <button
              className="Buttonresponsive"
              onClick={() => setShowList(!showList)}
            >
              {" "}
              <MdReorder style={{ zoom: '1.8' }} />
            </button>
            <ul className="list-usability">
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  onClick={aumentarFonte}
                >
                  <span>A+</span>
                  Aumentar
                </button>
              </li>
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  onClick={diminuirFonte}
                >
                  <span>A-</span>
                  Diminuir
                </button>
              </li>
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  onClick={() => setAutoContraste(!autoContraste)}
                >
                  <span className="i-alto-contraste"></span>
                  Alto contraste
                </button>
              </li>
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  onClick={() => openNewTab('https://escoladotrabalhador40.tawk.help/')}
                >
                  <MdLayers style={{ zoom: '2' }} />
                  {' '}Dúvidas Frequentes
                </button>
              </li>
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  //onClick={() => openNewTab('https://escolatrabalhador4.sharepoint.com/:b:/s/portalservicos/ERvOCvwXAl5BurX2ig9orEUBWpkOKV7fxvfK9QXEmPbdmA?e=Cxo6b3')}
                  onClick={() => openNewTab('/TERMO-DE-USO-EDT40-24072023.pdf')}
                >
                  <MdDescription style={{ zoom: '1.8' }} />
                  {' '}Termo de uso da Escola do Trabalhador 4.0
                </button>
              </li>
              <li id={showList ? "hidden" : ""}>
                <button
                  type="button"
                  className="btn btn-white btn-block"
                  onClick={() => openNewTab('https://drive.google.com/file/d/1WXBA_V_6L-Fk0LegFMv76CTt2MZNpxqG/view')}
                >
                  <MdPlayCircle style={{ zoom: '2' }} />
                  Vídeo tutorial
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="hold-contrast">
        <div
          className={`content ${autoContraste ? "contaste" : ""} ${tamanhos[tamanho].fontSize
            }`}
        >
          {/* <div class="row text-center" style={{ "paddingLeft": "50px", "paddingRight": "50px"}}>
            <div class="alert alert-success text-center" style={{"width": "100%"}}>Informamos que a emissão de certificados foi solucionada, e os novos certificados já podem ser gerados pela Escola do Trabalhador 4.0.</div>
            </div> */}
            <div className="row">
              <div className="col-12 col-md-6 text-right">
                <div className="logo" style={{paddingBottom: 0}}>
                  <a href="#" onClick={() => setPage("inicio")}>
                    <img src={Logo} alt="logo" className="logo-white" />
                    {/* <img className={`logoContraste ${autoContraste ? 'comContraste' : ''}`} src={LogoBlack} alt="logo"/> */}
                  </a>
                </div>
              </div>
            </div>

            {page && page === "inicio" && (
              <Inicio
                autoContraste={autoContraste}
                tamanhos={tamanhos}
                tamanho={tamanho}
                setPage={setPage}
              />
            )}
            {page && page === "alterar-senha" && (
              <AlterarSenha
                autoContraste={autoContraste}
                tamanhos={tamanhos}
                tamanho={tamanho}
                setPage={setPage}
                setData={setData}
                setShowProgress={setShowProgress}
              />
            )}
            {page && page === "cadastro" && (
              <Cadastro
                autoContraste={autoContraste}
                tamanhos={tamanhos}
                tamanho={tamanho}
                setPage={setPage}
                data={data}
                setData={setData}
                setShowProgress={setShowProgress}
                setShowTermos={setShowTermos}
              />
            )}
            {page && page === "fim" && (
              <Fim
                autoContraste={autoContraste}
                tamanhos={tamanhos}
                tamanho={tamanho}
                data={data}
                setPage={setPage}
              />
            )}
            <section className="hold-emprega">
              <br></br>
              <br></br>
              <br></br>
              <div className="container">
                <div className="row">
                  <div className="col text-center">
                    <h6 className="titulo-realizacao text-center" style={{ 'margin': '0 auto' }}>
                      PARCEIROS
                    </h6>
                    <div className="row">
                      <div className="col text-center">
                        <a href="https://softex.br/" target="_blank">
                          <img src={Logos1} alt="Softex" style={{ 'maxHeight': '63px' }} />
                        </a>
                      </div>

                      <div className="col text-center">
                        <a href="https://www.microsoft.com/" target="_blank">
                          <img
                            style={{ marginLeft: "2em" }}
                            src={Logos3}
                            alt="Microsoft"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <h6 className="titulo-realizacao text-center" style={{ 'margin': '0 auto' }}>
                      REALIZAÇÃO
                    </h6>
                    <div className="row">
                      <div className="col text-center">
                        <a href="https://www.gov.br/trabalho-e-previdencia/pt-br/servicos/trabalhador/qualificacao-profissional/caminho-digital" target="_blank">
                          <img src={Logos6} alt="Caminho Digital" style={{ 'maxHeight': '63px' }} />
                        </a>
                      </div>

                      <div className="col text-center">
                        <a href="https://www.gov.br/trabalho-e-emprego/pt-br" target="_blank">
                          <img
                            style={{ marginLeft: "2em" }}
                            src={Logos7}
                            alt="Ministério do Trabalhdo e Emprego"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 text-center">
                    <br />
                    <p className=" text-center" style={{ color: "#fff", fontWeight: "bold" }}>
                      Necessita de ajuda? Acesse: "Dúvidas Frequentes"
                    </p>
                  </div>
                </div>

                {/* <div className="row justify-content-start">
                <h6 className="titulo-realizacao">
                  PARCEIROS
                </h6>

              </div> */}
                {/* <div className="row justify-content-start">

                <div className="col-6 col-md-3">
                  <a href="https://softex.br/" target="_blank">
                    <img src={Logos1} alt="Softex" style={{ 'maxHeight': '73px' }} />
                  </a>
                </div>

                <div className="col-6 col-md-3">
                  <a href="https://www.microsoft.com/" target="_blank">
                    <img
                      style={{ marginLeft: "2em" }}
                      src={Logos3}
                      alt="Microsoft"
                    />
                  </a>
                </div>
              </div> */}

                {/* <div className="row justify-content-around">
                <h6 className="titulo-realizacao">REALIZAÇÃO</h6>
              </div> */}
                {/* <div className="row justify-content-end">

                <div className="col-6 col-md-3">
                  <a
                    href="https://www.gov.br/produtividade-e-comercio-exterior/pt-br"
                    target="_blank"
                  >
                    <img
                      src={Logos5}
                      alt="CEPEC - Ministério da Economia Governo Federal"
                    />
                  </a>
                </div>

                <div className="col-9 col-md-5">
                  <a href="https://www.gov.br/" target="_blank">
                    <img
                      src={Logos4}
                      alt="Ministério da Economia Governo Federal"
                    />
                  </a>
                </div>
              </div> */}


              </div>
            </section>
          </div>

          <Termos
            visible={showTermos}
            setVisible={setShowTermos}
            autoContraste={autoContraste}
            tamanhos={tamanhos}
            tamanho={tamanho}
          />
        </div>
    </section>
  );
}

export default App;
