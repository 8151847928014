function Inicio({ autoContraste, tamanhos, tamanho, setPage }) {

  const date = new Date();
  const showHour = date.getHours() 
  const showDay = date.getDate() 

  return (
    <div className="inicio">
      
      
        {/* { (showDay == 22 && showHour >= 17) || showDay > 22 ? <div className="row">
          <div className="col-2 col-md-2"></div>
          <div className="col-8 col-md-8">
            <div className="text-center alert alert-info">A plataforma de cursos está temporariamente fora do ar. Estamos realizando ajustes e, em breve, você poderá acessar novamente.
              <br />Agradecemos sua compreensão.
            </div>
          </div>
        </div> : '' 
} */}

      <div className="row">     
        <div className="col-12 col-md-6">
          <div className="row hold-register">
            <div className="col-12 col-md-8"></div>
            <div className="col-12 col-md-4">
              <button
                type="button"
                className={`btn btn-inicio ${autoContraste ? 'btn-outline-light' : 'btn-blue'} ${tamanhos[tamanho].btnSize}`}
                onClick={() => window.open('https://servicos.escoladotrabalhador40.com.br', '_blank')}>
                ENTRAR
             </button>

             <p style={{'marginTop': '40px'}}>PRIMEIRA VEZ POR AQUI?</p>         
              <button
                type="button"
                className={`btn btn-inicio ${autoContraste ? 'btn-outline-light' : 'btn-yellow'} ${tamanhos[tamanho].btnSize}`}
                onClick={() => setPage('alterar-senha')}>
                FAÇA SEU CADASTRO
              </button>

              <a
                type="button" href="https://www.escoladotrabalhador40.com.br" target="_blank" rel="noreferrer" style={{'marginTop': '40px'}}
                className={`btn btn-inicio ${autoContraste ? 'btn-outline-light' : 'btn-green'} ${tamanhos[tamanho].btnSize}`}>
                CONHEÇA A ESCOLA DO TRABALHADOR 4.0
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center mt-4 hold-register">
        <p >VÍDEO TUTORIAL</p> 
          <iframe src="https://drive.google.com/file/d/1Ovt6fdDrGju6c1NG1DFf0VTbh5RMwZpE/preview" width="500" height="283" allow="autoplay"></iframe>
        </div>
      </div>
      {/* <div className="row hold-register">  
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-md-8"></div>
            <div className="col-12 col-md-4">
              <p>PRIMEIRA VEZ POR AQUI?</p>         
              <button
                type="button"
                className={`btn btn-inicio ${autoContraste ? 'btn-outline-light' : 'btn-yellow'} ${tamanhos[tamanho].btnSize}`}
                onClick={() => setPage('alterar-senha')}>
                FAÇA SEU CADASTRO
              </button>
            </div>
            <div className="col-12 col-md-8"></div>
            <div className="col-12 col-md-4" style={{marginTop: '2em'}}>
              <a
                type="button" href="https://www.escoladotrabalhador40.com.br" target="_blank" rel="noreferrer"
                className={`btn btn-inicio ${autoContraste ? 'btn-outline-light' : 'btn-green'} ${tamanhos[tamanho].btnSize}`}>
                CONHEÇA A ESCOLA DO TRABALHADOR 4.0
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    
  );
}

export default Inicio;